import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import { FACETS } from '../constants';

const FacetDropdown = ({
  name,
  options,
  handleFacetChange,
  intl,
}) => {
  const getLocalizedFacetName = (facetName) => {
    const nameLower = facetName.toLowerCase();
    switch (nameLower) {
      case FACETS.LANGUAGE:
        return intl.formatMessage({ id: 'facets.language', defaultMessage: 'Language' });
      case FACETS.ROLE:
        return intl.formatMessage({ id: 'facets.role', defaultMessage: 'Role' });
      case FACETS.TOPIC:
        return intl.formatMessage({ id: 'facets.topic', defaultMessage: 'Topic' });
      case FACETS.SERIES:
        return intl.formatMessage({ id: 'facets.series', defaultMessage: 'Series' });
      default:
        return nameLower[0].toUpperCase + facetName.slice(1);
    }
  };

  return (
    <div id={`facet-${name}`}>
      <select
        id={`${name}-input`}
        name={name}
        value=""
        onChange={(e) => handleFacetChange(e, name)}
      >
        <option key="blank" value="">{getLocalizedFacetName(name)}</option>
        {options.map((facetOption) => (
          <option
            className="facet-option discovery-button"
            key={`facet-${name}-${facetOption}`}
            value={facetOption}
          >
            {facetOption}
          </option>
        ))}
      </select>
    </div>
  );
};

FacetDropdown.propTypes = {
  intl: intlShape.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.oneOf(Object.values(FACETS)).isRequired,
  handleFacetChange: PropTypes.func.isRequired,
};

export default injectIntl(FacetDropdown);
