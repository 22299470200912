import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { getConfig } from '@edx/frontend-platform';

import { getCourseData } from '../../api';
import CourseDetails from './CourseDetails';
import CourseSidebar from './CourseSidebar';

const CourseAbout = () => {
  const { courseId } = useParams();
  const [courseData, setCourseData] = useState({});
  const [isNotFound, setIsNotFound] = useState(false);

  useEffect(() => {
    getCourseData(courseId)
      .then(responseData => {
        setCourseData(responseData);
      })
      .catch((err) => {
        if (err.customAttributes.httpErrorStatus === 404) {
          setIsNotFound(true);
        }
      });
  }, []);

  const {
    available_languages: availableLanguages,
    course_about_sections: courseAboutSections,
    course_image_urls: courseImages,
    enroll_options: enrollOptions,
    staff_access: staffAccess,
    studio_url: studioUrl,
    course,
    collections,
  } = courseData;

  if (isNotFound) {
    return <Redirect to="/notfound" />;
  }

  return (
    <>
      <Helmet>
        <title>{`${course?.display_name_with_default || 'Course About'} | ${getConfig().SITE_NAME}`}</title>
      </Helmet>
      <div className="course-about">
        <div className="container">
          {course && courseAboutSections && (
            <>
              <CourseDetails
                staffAccess={staffAccess}
                studioUrl={studioUrl}
                courseName={course?.display_name_with_default}
                overview={courseAboutSections?.overview}
              />
              <CourseSidebar
                courseNumber={course.display_number_with_default}
                courseImageUrl={courseImages?.small}
                effort={courseAboutSections.effort}
                collections={collections}
                enrollOptions={enrollOptions}
                availableLanguages={availableLanguages}
                courseId={courseId}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CourseAbout;
