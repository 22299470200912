import React from 'react';
import { CourseCard } from './components';

export const buildCourseList = (courses, curriculaData) => courses.map((course) => {
  const data = 'data' in course ? course.data : course;
  const courseData = {
    courseName: data.content ? data.content.display_name : data.display_name,
    courseOrg: data.org,
    courseNumber: data.number,
    courseLink: `/courses/${data.course}/about`,
    courseStart: new Date(data.start),
    courseEnrollmentStart: data.enrollment_start,
    courseImg: data.image_url,
    series: data.series,
    curricula: {},
  };

  if (curriculaData) {
    Object.keys(curriculaData).forEach((type) => {
      courseData.curricula[type] = data[type];
    });
  }

  return <CourseCard key={data.id || data.course} {...courseData} />;
});

/**
 * Filter facets to show only language-specific ones.
 * @param {Object} originalFacets - original facets to filter.
 * @param {Object} curriculaData - filtering criteria for roles and topics.
 * @param {Array} seriesData - filtering criteria for series.
 * @returns
 */
export const filterFacets = (originalFacets, curriculaData, seriesData) => {
  if (!Object.keys(originalFacets).length) {
    return originalFacets;
  }
  // Can't use { ...originalFacets } or Object.assign()/Object.create() because nested
  // objects will have the same references (I don't need to change the originalFacets)
  const facets = JSON.parse(JSON.stringify(originalFacets));

  const allowedRoles = [];
  const allowedTopics = [];
  const allowedSeries = [];

  if (curriculaData.role && curriculaData.role.length) {
    curriculaData.role.forEach(item => allowedRoles.push(item.title));
  }

  if (curriculaData.topic && curriculaData.topic.length) {
    curriculaData.topic.forEach(item => allowedTopics.push(item.title));
  }

  seriesData.forEach(item => allowedSeries.push(item.title));

  facets.role.terms = Object.keys(facets.role.terms)
    .filter(title => allowedRoles.includes(title))
    .reduce((obj, key) => ({
      ...obj,
      [key]: facets.role.terms[key],
    }), {});

  facets.topic.terms = Object.keys(facets.topic.terms)
    .filter(title => allowedTopics.includes(title))
    .reduce((obj, key) => ({
      ...obj,
      [key]: facets.topic.terms[key],
    }), {});

  facets.series.terms = Object.keys(facets.series.terms)
    .filter(title => allowedSeries.includes(title))
    .reduce((obj, key) => ({
      ...obj,
      [key]: facets.series.terms[key],
    }), {});

  return facets;
};
