import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import { FACETS } from '../constants';
import FacetDropdown from './FacetDropdown';

/**
 * Search Bar component that renders search input, search button and Facets selects.
 */
const SearchBar = ({
  facets,
  queryString,
  handleSearch,
  handleFacetChange,
  handleQueryStringChange,
  intl,
}) => (
  <div className="search-wrapper">
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSearch();
      }}
    >
      <div className="search-input">
        <span className="sr">
          <FormattedMessage id="search.searchForACourse" defaultMessage="Search for a course" />
        </span>
        <input
          id="discovery-input"
          className="discovery-input"
          placeholder={intl.formatMessage({
            id: 'search.placeholder',
            defaultMessage: 'Enter a course name or keyword to search...',
          })}
          value={queryString}
          onChange={handleQueryStringChange}
          type="text"
        />
        <button type="submit" className="button postfix discovery-submit custom-search" title="Search">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M500.3 443.7 380.6 324c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723 99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9 53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0 15.606-15.64 15.606-41.04.006-56.64zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128-57.42 128-128 128-128-57.4-128-128z"/>
          </svg>
          <div aria-live="polite" aria-relevant="all">
            <div id="loading-indicator" className="loading-spinner hidden">
              <span className="icon fa fa-spinner fa-spin" aria-hidden="true" />
              <span className="sr">
                <FormattedMessage id="loading" defaultMessage="Loading" />
              </span>
            </div>
          </div>
        </button>
      </div>
      <div className="facet-dropdown">
        {Object.keys(facets).length > 0 && Object.values(FACETS).map((facetName) => (
          <FacetDropdown
            key={facetName}
            name={facetName}
            options={Object.keys(facets[facetName].terms)}
            handleFacetChange={handleFacetChange}
          />
        ))}
      </div>
    </form>
  </div>
);

SearchBar.propTypes = {
  intl: intlShape.isRequired,
  facets: PropTypes.shape({}),
  selectedFacets: PropTypes.shape({}),
  handleQueryStringChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleFacetChange: PropTypes.func.isRequired,
  queryString: PropTypes.string.isRequired,
};

SearchBar.defaultProps = {
  facets: {},
  selectedFacets: {},
};

export default injectIntl(SearchBar);
