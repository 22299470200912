import React, { useContext } from 'react';
import Header from '@edx/frontend-component-header';
import Footer from '@edx/frontend-component-footer';
import { Switch, useLocation, Redirect } from 'react-router-dom';
import { PageRoute } from '@edx/frontend-platform/react';
import SearchContext from './context/SearchContext';
import Search from './search';
import {
  DiscoverCourses,
  CurriculumAbout,
  CourseAbout,
  SeriesAbout,
  NotFoundPage,
} from './pages';

const App = () => {
  const { searchResult } = useContext(SearchContext);
  const location = useLocation();

  return (
    <div className="main-wrapper">
      <Header />
      <Switch>
        <PageRoute exact path="/notfound" component={NotFoundPage} />
        <PageRoute path="*">
          <div id="frontend-component-custom-search">
            <div className="content-wrapper main-container">
              <main id="main" aria-label="Content" tabIndex="-1">
                <section className="find-courses">
                  <section className="courses-container">
                    {searchResult.length === 0 && location.pathname === '/courses' && (
                    <h1 className="search-title">
                      ab initio
                      <span>training</span>
                    </h1>
                    )}
                    <Search />
                    <Switch>
                      <PageRoute exact path="/courses" component={DiscoverCourses} />
                      <PageRoute path="/series/:seriesId" component={SeriesAbout} />
                      <PageRoute path="/curriculum/:curriculumId" component={CurriculumAbout} />
                      <PageRoute path="/courses/:courseId/about" component={CourseAbout} />
                      <PageRoute path="*" component={() => <Redirect to="/notfound" />} />
                    </Switch>
                  </section>
                </section>
              </main>
            </div>
          </div>
        </PageRoute>
      </Switch>
      <Footer />
    </div>
  );
};

export default App;
