import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getConfig } from '@edx/frontend-platform';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';

import { enrollCourse } from '../../api';

const CourseSidebar = ({
  courseNumber,
  courseImageUrl,
  courseId,
  effort,
  collections,
  enrollOptions,
  availableLanguages,
  intl,
}) => {
  const [enrollErrorMessage, setEnrollErrorMessage] = useState('');
  const [optionAction, setOptionAction] = useState({});
  const [selectedCourseId, setSelectedCourseId] = useState(courseId);
  const [enrollmentInProgress, setEnrollmentInProgress] = useState(false);

  useEffect(() => {
    if (enrollOptions.length > 0 && !enrollOptions.find(element => element.option_id == selectedCourseId)) {
      setSelectedCourseId(enrollOptions[0].option_id);
    }
    setOptionAction(getOptionAction(selectedCourseId));
  }, [selectedCourseId]);

  const getOptionAction = (selectedCourseId) => {
    const option = enrollOptions?.find(element => element.option_id == selectedCourseId);
    if (option?.registered) {
      return {
        message: intl.formatMessage({ id: 'courseAbout.enrolled', defaultMessage: 'You are enrolled in this course' }),
        courseTarget: option.course_target,
        disabled: true,
      };
    } else if (option?.is_course_full) {
      return {
        message: intl.formatMessage({ id: 'courseAbout.courseIsFull', defaultMessage: 'Course is full' }),
        disabled: true,
      };
    } else if (option?.invitation_only && !option?.can_enroll) {
      return {
        message: intl.formatMessage({ id: 'courseAbout.invitationOnlyCourse', defaultMessage: 'Enrollment in this course is by invitation only' }),
        disabled: true,
      };
    } else if (!option || option?.is_shib_course && !option?.can_enroll) {
      return {
        message: intl.formatMessage({ id: 'courseAbout.enrollmentIsClosed', defaultMessage: 'Enrollment is Closed' }),
        disabled: true,
      };
    } else {
      return {
        message: intl.formatMessage({ id: 'courseAbout.enrollNow', defaultMessage: 'Enroll Now' }),
        disabled: false,
      };
    }
  };

  const enrollCourseHandler = async () => {
    setEnrollErrorMessage('');
    setEnrollmentInProgress(true);
    const enrollmentResponse = await enrollCourse(selectedCourseId);
    if (enrollmentResponse === "") {
      window.location.assign(`${getConfig().LMS_BASE_URL}/dashboard`);
    } else {
      setEnrollErrorMessage(enrollmentResponse);
      setEnrollmentInProgress(false);
    }
  };

  const selectLangHandler = (e) => {
    setSelectedCourseId(e.target.value);
  };

  return (
    <div className="course-sidebar">
      <div className="course-summary">

        <header className="course-profile">
          <div className="intro-inner-wrapper">
            <div className="table">
              <div className="media">
                <div className="hero">
                  <img src={`${getConfig().LMS_BASE_URL}${courseImageUrl}`} alt=""/>
                </div>
              </div>
            </div>
          </div>
        </header>

        <ol className="important-dates">
          <li className="important-dates-item" key='course-number'>
            <p className="important-dates-item-title">
              {intl.formatMessage({ id: 'courseAbout.courseNumber', defaultMessage: 'Course Number:' })}
            </p>
            <span className="important-dates-item-text course-number">{courseNumber}</span>
          </li>
          {availableLanguages.length > 0 &&
            <li className="important-dates-item important-dates-item--languages" key="languages">
              <p className="important-dates-item-title">
                {intl.formatMessage({ id: 'courseAbout.availableLanguages', defaultMessage: 'Available Language(s):' })}
              </p>
              <div className="important-dates-item-links">
                {availableLanguages?.map(([lang, link], i) => {
                  const sep = i + 1 < availableLanguages.length ? ', ' : '';
                  return (<a href={link} key={lang}>{lang}{sep}</a>);
                })}
              </div>
            </li>
          }
          {effort && (
            <li className="important-dates-item" key="course-start">
              <p className="important-dates-item-title">
                {intl.formatMessage({ id: 'courseAbout.effort', defaultMessage: 'Estimated Effort:' })}
              </p>
              <span>{effort}</span>
            </li>
          )}
          {collections && Object.entries(collections).map(([key, value]) => (
            <li className="important-dates-item" key={key}>
              <p className="important-dates-item-title">
                {key}:
              </p>
              <span>{value}</span>
            </li>
          ))}
        </ol>

          <div className="main-cta">
            {enrollOptions.length > 0 && 
              <div className="preferred-language-section">
                <span className="preferred-language-text">
                  {intl.formatMessage({ id: 'courseAbout.enrollIn', defaultMessage: 'Enroll in:' })}
                </span>
                <select role='select' className="lang-option-select" onChange={selectLangHandler} value={selectedCourseId}>
                  {Object.entries(enrollOptions).map(([_, enrollOption], index) => (
                    <option
                      value={enrollOption.option_id}
                      data-option-index={index}
                      key={index}
                    >
                      {enrollOption.option_lang}
                    </option>
                  ))}
                </select>
              </div>
            }
            <div className="option-actions">
              <button type="button" disabled={optionAction.disabled || enrollmentInProgress} className="register" onClick={enrollCourseHandler}>
                {optionAction.message}
              </button>
              <div id="register_error">{enrollErrorMessage}</div>
              {optionAction.courseTarget && (
                <a href={getConfig().LMS_BASE_URL + optionAction.courseTarget}>
                  {intl.formatMessage({ id: 'courseAbout.viewCourse', defaultMessage: 'View Course' })}
                </a>
              )}
            </div>
          </div>
      </div>
    </div>
  );
};

CourseSidebar.propTypes = {
  courseNumber: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
  collections: PropTypes.shape({}).isRequired,
  enrollOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  availableLanguages: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  courseImageUrl: PropTypes.string.isRequired,
  effort: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(CourseSidebar);
