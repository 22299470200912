import React from 'react';
import PropTypes from 'prop-types';

import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';

const CourseDetails = ({
  staffAccess,
  studioUrl,
  courseName,
  overview,
  intl,
}) => (
  <div className="details">
    <header className="course-profile">
      <div className="intro-inner-wrapper">
        <div className="table">
          <section className="intro">
            <div className="heading-group">
              <h1>{courseName}</h1>
            </div>
          </section>
        </div>
      </div>
    </header>
    {staffAccess && (
      <div className="wrap-instructor-info studio-view">
        <a className="instructor-info-action" href={studioUrl}>{intl.formatMessage({ id: 'courseAbout.viewInStudio', defaultMessage: 'View About Page in studio' })}</a>
      </div>
    )}
    <div className="inner-wrapper" role="overview" dangerouslySetInnerHTML={{ __html: overview }} />
  </div>
);

CourseDetails.propTypes = {
  intl: intlShape.isRequired,
  staffAccess: PropTypes.bool.isRequired,
  studioUrl: PropTypes.string.isRequired,
  courseName: PropTypes.string.isRequired,
  overview: PropTypes.string.isRequired,
};

export default injectIntl(CourseDetails);
