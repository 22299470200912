import React from 'react';
import PropTypes from 'prop-types';

/**
 * Render a list of courses.
 */
const SearchResult = ({ message, courses }) => (
  <>
    <div id="discovery-form" role="search" aria-label="course" className="wrapper-search-context">
      <div id="discovery-message" className="search-status-label">
        {message}
      </div>
    </div>
    <div className="courses no-course-discovery" role="region" aria-label="List of Courses">
      <ul className="courses-listing courses-list">
        {courses}
      </ul>
    </div>
  </>
);

export default SearchResult;

SearchResult.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.shape({})),
  message: PropTypes.string.isRequired,
};

SearchResult.defaultProps = {
  courses: [],
};
