import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getConfig } from '@edx/frontend-platform';
import { FormattedMessage } from '@edx/frontend-platform/i18n';

/**
 * Course Card component that renders provided course data.
 */
const CourseCard = ({
  courseName,
  courseNumber,
  courseLink,
  courseImg,
  curricula,
  series,
}) => {
  const getCollectionTitle = (title) => {
    const titleLower = title.toLowerCase();
    switch (titleLower) {
      case 'role':
        return <FormattedMessage id="roles" defaultMessage="Roles" />;
      case 'topic':
        return <FormattedMessage id="topics" defaultMessage="Topics" />;
      case 'series':
        return <FormattedMessage id="series" defaultMessage="Series" />;
      default:
        return `By ${titleLower[0].toUpperCase() + titleLower.slice(1)}`;
    }
  };

  const inCollections = (curriculaData) => {
    const collectionsList = Object.entries(curriculaData).map(([type, titles]) => (
      titles && titles.length
        ? (
          <div className="course-date" aria-hidden="true" key={type}>
            <b>{getCollectionTitle(type)}: </b>
            {titles.join(', ')}
          </div>
        )
        : null
    ));
    if (series && series.length) {
      collectionsList.push(
        <div className="course-date" aria-hidden="true" key="series-titles">
          <b><FormattedMessage id="series" defaultMessage="Series" />: </b>
          {series.join(', ')}
        </div>,
      );
    }
    return collectionsList;
  };

  return (
    <li className="courses-listing-item">
      <article className="course" id="course.id" role="region" aria-label={courseName}>
        <Link to={courseLink} style={{ textDecoration: 'none' }}>
          <header className="course-image">
            <div className="cover-image">
              <img src={`${getConfig().LMS_BASE_URL}${courseImg}`} alt={`${courseName} ${courseNumber}`} />
              <div className="learn-more" aria-hidden="true">
                <FormattedMessage id="learnMore" defaultMessage="LEARN MORE" />
              </div>
            </div>
          </header>
          <div className="course-info" aria-hidden="true">
            <h2 className="course-name">
              <span className="course-title">{courseName}</span>
            </h2>
            {inCollections(curricula)}
          </div>
        </Link>
      </article>
    </li>
  );
};

CourseCard.propTypes = {
  courseName: PropTypes.string.isRequired,
  courseNumber: PropTypes.string.isRequired,
  courseLink: PropTypes.string.isRequired,
  courseImg: PropTypes.string.isRequired,
  curricula: PropTypes.shape({}).isRequired,
  series: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CourseCard;
