import React, { useContext } from 'react';
import SearchBar from './SearchBar';
import FilterBar from './FilterBar';
import SearchContext from '../context/SearchContext';
import { filterFacets } from '../utils';

const Search = () => {
  const {
    curriculaData,
    seriesData,
    facets,
    queryString,
    selectedFacets,
    handleFacetChange,
    handleQueryStringChange,
    handleSearch,
    handleClearFacet,
    handleClearAllFacets,
  } = useContext(SearchContext);

  return (
    <>
      <SearchBar
        facets={filterFacets(facets, curriculaData, seriesData)}
        queryString={queryString}
        selectedFacets={selectedFacets}
        handleFacetChange={handleFacetChange}
        handleQueryStringChange={handleQueryStringChange}
        handleSearch={handleSearch}
      />
      {Object.keys(selectedFacets).length > 0 && (
        <FilterBar
          selectedFacets={selectedFacets}
          handleClearFacet={handleClearFacet}
          handleClearAllFacets={handleClearAllFacets}
        />
      )}
    </>
  );
};

export default Search;
